


































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import StatusTag from '@/components/media/CommissionStatusTag.vue';

interface VuexBindings {
  findCommission: (COMMISSION_ID: string) => ApiResource.Commission | undefined,
  findCommissionStats: (COMMISSION_ID: string) => ApiResource.CommissionStats | undefined,
  findProfile: (profileId: string) => ApiResource.Profile | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    StatusTag,
  },
  computed: {
    ...mapGetters({
      findCommission: 'commission/Find',
      findCommissionStats: 'commission/Stats',
      findProfile: 'profiles/Find',
      listEvents: 'commission/event/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    commission(): ApiResource.Commission {
      return this.findCommission(this.COMMISSION_ID);
    },
    profile(): ApiResource.Profile | undefined {
      if (!this.commission) return undefined;
      return this.findProfile(this.commission.business_id);
    },
    stats(): ApiResource.CommissionStats | undefined {
      return this.findCommissionStats(this.COMMISSION_ID);
    },
  },
});
