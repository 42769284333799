var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTag',{staticClass:"is-rounded",class:{
    'is-danger': _vm.color === 'danger',
    'is-link': _vm.color === 'link',
    'is-primary': _vm.color === 'primary',
    'has-text-danger': _vm.textColor === 'danger',
    'has-text-link': _vm.textColor === 'link',
    'has-text-primary': _vm.textColor === 'primary',
  },domProps:{"textContent":_vm._s(_vm.text)}})}
var staticRenderFns = []

export { render, staticRenderFns }