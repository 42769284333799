<template>
  <BaseTag
    class="is-rounded"
    :class="{
      'is-danger': color === 'danger',
      'is-link': color === 'link',
      'is-primary': color === 'primary',
      'has-text-danger': textColor === 'danger',
      'has-text-link': textColor === 'link',
      'has-text-primary': textColor === 'primary',
    }"
    v-text="text"
  />
</template>

<script>
export default {
  props: {
    status: {
      required: true,
      type: String,
      validator(str) {
        return [
          'waiting',
          'requested',

          'active',
          'pending',
          'complete',

          'stale',
          'idle',

          'finished',
          'archived',

          'cancelled',
          'rejected',
          'annulled',
          'dropped',
          'stopped',
          'suspended',
          'removed',
        ].includes(str);
      },
    },
  },
  computed: {
    color() {
      let color;

      switch (this.status) {
        case 'complete':
          color = 'link';
          break;

        case 'pending':
          color = 'danger';
          break;

        default:
          break;
      }

      return color;
    },
    textColor() {
      let color;

      switch (this.status) {
        case 'requested':
          color = 'link';
          break;

        case 'active':
          color = 'primary';
          break;

        case 'stale':
        case 'idle':
          color = 'danger';
          break;

        case 'cancelled':
        case 'rejected':
        case 'annulled':
        case 'dropped':
        case 'stopped':
        case 'suspended':
        case 'removed':
          color = 'danger';
          break;

        default:
          break;
      }

      return color;
    },
    text() {
      return this.$t(this.status);
    },
  },
};
</script>

<i18n lang="yaml" locale="en">
waiting: Waiting
requested: Requested

active: Active
pending: Pending customer action
complete: Complete, pending approval

stale: Stale
idle: Idle

finished: Finished
archived: Archived

cancelled: Cancelled by Customer
rejected: Rejected
annulled: null
dropped: null
stopped: null
suspended: null
removed: null
</i18n>
<i18n lang="yaml" locale="de">
waiting: Wartend
requested: Angefragt

active: Aktiv
pending: Ausstehende Kundenaktion
complete: Abgeschlossen, erwarte Bestätigung

idle: Inaktiv

finished: Fertig
archived: Archiviert

cancelled: Storniert
rejected: Abgelehnt
annulled: null
dropped: null
stopped: null
suspended: null
removed: null
</i18n>
